<template>
  <div class="relative mb-4 bg-gradient-to-r from-trublue-200/80 to-trublue-50/50">
    <div class="relative overflow-hidden p-6">
      <div class="md:border-l-4 md:border-l-trublue md:pl-5">
        <div class="mb-2 flex items-center justify-start text-xl font-bold md:text-2xl">
          {{ translateLabel ? $t('dashboards.touchpoint.name.' + label) : label }}

          <UIcon
            name="i-mdi-cog-outline"
            class="mx-3 mt-1 h-auto w-5 cursor-pointer text-trublue hover:text-blue-3"
            @click="$emit('update:showSettingsDialog', true)"
          />
        </div>
        <div
          v-if="noDataAvailable || loadingData"
          class="flex items-center gap-2 text-xs font-bold text-grey-blue"
        >
          <UIcon
            :name="loadingData ? 'i-line-md-loading-loop': 'i-mdi-information-outline'"
            class="text-lg"
          />
          <div class="w-3/5 lg:w-auto">
            {{ loadingData ? $t('loadingData') : $t('dashboards.touchpoint.noData') }}
          </div>
        </div>
        <div
          v-else
          class="flex w-1/2 flex-wrap gap-x-6 gap-y-1 text-xs font-semibold text-grey-blue md:w-3/5 "
          :class="{
            'lg:w-full lg:items-center': !showAllStats,
            'lg:w-2/3 lg:items-center': showAllStats,
          }"
        >
          <div
            v-for="[index, statistic] of Object.entries(showAllStats ? statistics : basicStatistics)"
            :key="index"
          >
            <span class="text-xs">
              {{ $t(`futureBackendTranslations.touchpointBanner.${index.replace(/[[\]]/g, '')}`) }}:
            </span>
            <span class="ml-1 text-xs lg:text-base">
              {{ statistic || '0' }}
            </span>
          </div>
          <div
            v-if="Object.keys(statistics).length"
            class="mt-0.5 w-full lg:inline-block lg:w-auto"
          >
            <button
              class="text-xs font-bold text-trublue hover:text-trublue-600"
              @click="showAllStats = !showAllStats"
            >
              {{ !showAllStats ? $t('seeMore') : $t('seeLess') }}
            </button>
          </div>
        </div>
      </div>
      <img
        src="~/assets/img/tru-background.svg"
        class="absolute right-0 top-2 hidden opacity-80 md:block"
      >
      <div
        class="absolute -right-10 top-0 md:right-10"
      >
        <NuxtImg
          :src="`/images/touchpoints/${svg || 'default.svg'}`"
          class="h-36 max-w-44 md:min-w-72 lg:max-w-none"
        />
      </div>
    </div>
    <div
      v-if="visibilityToggle && !(noDataAvailable || loadingData)"
      class="absolute -bottom-3 flex w-full justify-center print:hidden"
    >
      <HubButton
        :id="'touchpoint-visibility-toggle' + (isVisible ? '-hide' : '-show')"
        btn-style="mr-2 mt-4 bg-trublue rounded-full p-1 z-10"
        icon-only
        icon-classes="text-white"
        :icon="'i-mdi-' + (isVisible ? 'chevron-up' : 'chevron-down')"
        @click="setIsVisible"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ServerColumnRow, ServerDataRow } from '~/types/component'
import type { HubTouchpointConfig, HubTouchpointConfigKey } from '~/types/configuration'

interface HubTouchpointBannerProps extends HubTouchpointConfig {
  dashboardId: string
  visibilityToggle?: boolean
  showSettingsDialog?: boolean
}

const props = withDefaults(defineProps<HubTouchpointBannerProps>(), {
  visibilityToggle: true
})
const emit = defineEmits<{
  (e: 'update', arg1: HubTouchpointConfigKey, arg2: HubTouchpointConfig[HubTouchpointConfigKey]): void
  (e: 'update:showSettingsDialog', arg1: boolean): void
}>()

const { n, locale } = useI18n()

// store refs
const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)

// internal refs
const loadingData = ref(false)
const serverResponse = ref()
const showAllStats: Ref<boolean> = ref(false)
const statistics: Ref<{ [name: string]: string | number }> = ref({})

// computed refs
const basicStatistics: ComputedRef<{ [name: string]: string | number }> = computed(() => {
  // filter out the statistics that are not basic
  const basicStats = Object.keys(statistics.value).filter(stat => {
    return !['[Skip Rate]', '[Timeout]'].includes(stat)
  })

  return basicStats.reduce((acc, stat) => {
    // @ts-expect-error shhhh it's fine
    acc[stat] = statistics.value[stat]
    return acc
  }, {})
})

const noDataAvailable = computed(() => {
  return Object.values(statistics.value).some(value => !value)
})
const formattedQueryParams = computed(() => ({
  cultureInfo: locale.value ? locale.value.split('-')[0] : undefined,
  ...formatQueryParamsForAPI(params.value),
  ...(props.queryParams || {})
}))

// methods
const setIsVisible = useDebounceFn(() => {
  emit('update', 'isVisible', !props.isVisible)
}, 250)

// watch methods
watch(
  [() => props.dashboardId, () => params, () => locale],
  async () => {
    loadingData.value = true
    serverResponse.value = await $hubFetch(`api/v4/dashboards/${props.dashboardId}/reports/ResponseRateBanner`, {
      query: formattedQueryParams.value
    })
    loadingData.value = false
  },
  {
    deep: true,
    immediate: true
  }
)
watch(
  () => params.value.hash,
  () => {
    if (!params.value.hash) {
      return
    }
    // Set this touchpoint to visible and open if touchpoint matches hash
    const [touchpointId] = params.value.hash.split(':')

    if (touchpointId === props.id && !props.isVisible) {
      setIsVisible()
    }
  },
  {
    immediate: true
  }
)
watch(
  [() => serverResponse, () => locale.value],
  () => {
    if (!serverResponse.value) {
      return
    }

    const columns: Array<ServerColumnRow> = serverResponse.value.results.columnHeadings
    const data: Array<Array<ServerDataRow>> = serverResponse.value.results.rows
    const ignoredColumns: Array<number> = []

    for (const [index, col] of columns.entries()) {
      if (['[Calculation]', '[Skip Quantity]', '[Timeout Quantity]'].includes(col.dimensionCaption)) {
        ignoredColumns.push(index)
      }
    }

    statistics.value = flattenData(
      columns,
      data,
      ignoredColumns,
      (row: ServerDataRow) => {
        if (row.type == 'decimal') return n(row.value as number)
        return row.formattedValue || row.value
      }
    )[0] as { [name: string]: string | number }
  },
  {
    deep: true,
    immediate: true
  }
)
watch(
  () => noDataAvailable.value,
  val => {
    // If there is no data, hide the touchpoint - if there is data, show the touchpoint
    if (val && props.isVisible) {
      setIsVisible()
    }

    // if there is data and the toggle is hidden, show the touchpoint
    if (!val && !props.isVisible && !props.visibilityToggle) {
      setIsVisible()
    }
  },
  {
    immediate: true
  }
)
watch(
  () => props.visibilityToggle,
  val => {
    // If there is no toggle, data available and touchpoint is hidden, show the touchpoint
    if (!noDataAvailable.value && !val && !props.isVisible) {
      setIsVisible()
    }
  },
  {
    immediate: true
  }
)
</script>
