<template>
  <div>
    <div class="border-b-[0.5px] border-b-zinc-200">
      <div class="m-4 items-end justify-between gap-2 md:flex md:items-start">
        <div class="gap-2">
          <div class="flex items-center justify-start gap-2">
            <div
              id="dashboard-name"
              class="text-xl font-medium"
            >
              {{ dashboardObjectDeepClone.translateName
                ? $t('dashboards.name.' + dashboardObjectDeepClone.name)
                : dashboardObjectDeepClone.name }}
            </div>

            <UIcon
              v-if="dashboardObjectDeepClone.isDefault"
              id="dashboard-default-icon"
              name="i-mdi-home-outline"
              class="mb-0.5 !size-6 text-grey-blue"
            />
          </div>
          <div
            id="dashboard-subtitle"
            class="text-2xs font-semibold uppercase text-grey-blue"
          >
            {{ dashboardObjectDeepClone.rootOrganisationNodeName }}
          </div>
        </div>

        <DashboardActions
          v-model:dashboard="dashboardObjectDeepClone"
          :touchpoints="filteredTouchpoints"
          @save="updateDashboard(dashboardObjectDeepClone.config, true)"
          @update:dashboard="updateDashboard()"
        />
      </div>
    </div>

    <div>
      <HubTabs
        v-if="dashboardObjectDeepClone?.config?.touchpointDisplayType == 'tabs'"
        :items="computedTouchpoints"
      >
        <template #header="{ item }">
          {{ item?.translateLabel ? $t('dashboards.touchpoint.name.' + item.label) : item.label }}
        </template>
        <template #item="{ item }">
          <DashboardTouchpoint
            :dashboard="dashboardObjectDeepClone"
            :touchpoint="(item as HubTouchpointConfig)"
            @update-section="updateSection"
            @update-touchpoint="updateTouchpoint"
            @update-dashboard="updateDashboard"
          />
        </template>
      </HubTabs>
      <DashboardTouchpoint
        v-for="touchpoint in computedTouchpoints"
        v-else
        :key="touchpoint.id"
        :dashboard="dashboardObjectDeepClone"
        :touchpoint="touchpoint"
        @update-section="updateSection"
        @update-touchpoint="updateTouchpoint"
        @update-dashboard="updateDashboard"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import type { PartialExceptTheseRequired } from '~/types'
import type {
  HubDashboard,
  HubSectionConfig,
  HubTouchpointConfig,
  HubDashboardConfig,
  HubSectionItemConfig
} from '~/types/configuration'
import { getDifference } from '~/utils/general-helpers'

const props = defineProps<{ dashboardObject: HubDashboard }>()

const { t } = useI18n()
const route = useRoute()
const touchpointStore = useTouchpointStore()
const sectionStore = useSectionStore()
const dashboardStore = useDashboardStore()
const { currentOrganisationTouchpoints } = storeToRefs(touchpointStore)

const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)
const { allSections } = storeToRefs(sectionStore)

// internal refs
const dashboardObjectDeepClone = ref(cloneDeep(props.dashboardObject))
const filteredTouchpointIds = ref<Array<string>>([])
const filteredTouchpoints = ref<Array<HubTouchpointConfig>>([])

// init + check dashboard exists
await touchpointStore.fetchTouchpointForOrganisation(dashboardObjectDeepClone.value.rootOrganisationNodeId)
// needs to be awaited, otherwise the cloneDeep call in DashboardTouchpoint may happen to quickly
await sectionStore.fetchSections()
dashboardStore.fetchDashboardMetadata(dashboardObjectDeepClone.value.id)

// watchers
watch(
  () => props.dashboardObject,
  () => {
    dashboardObjectDeepClone.value = cloneDeep(props.dashboardObject)
    filteredTouchpoints.value = calculateTouchpoints()
  },
  {
    deep: true,
    immediate: true
  }
)
watch(
  [
    () => currentOrganisationTouchpoints.value,
    () => dashboardObjectDeepClone.value
  ],
  () => {
    filteredTouchpoints.value = calculateTouchpoints()
  },
  {
    deep: true
  }
)
watch(
  () => params.value,
  () => {
    if (!params.value.touchpointIds) {
      filteredTouchpointIds.value = []
      return
    }

    const touchpointIds = params.value.touchpointIds
    if (Array.isArray(touchpointIds)) {
      filteredTouchpointIds.value = touchpointIds as Array<string>
      return
    }

    filteredTouchpointIds.value = [touchpointIds as string]
  },
  {
    deep: true,
    immediate: true
  }
)

// computed
const computedTouchpoints = computed(() => {
  return filteredTouchpoints.value.filter(t => {
    if (filteredTouchpointIds.value.length > 0) {
      return filteredTouchpointIds.value.includes(t.id) && t.isEnabled
    }

    return t.isEnabled
  })
})
const canEditDashboard: ComputedRef<boolean> = computed(() => {
  return checkPermissions({
    permissions: props.dashboardObject.isOwner
      ? 'Dashboards.Default.Self.Update'
      : 'Dashboards.Default.Organisation.Update'
  })
})

// methods
function calculateTouchpoints(): Array<HubTouchpointConfig> {
  const touchpoints: Array<HubTouchpointConfig> = []

  if (!dashboardObjectDeepClone.value?.config) {
    return currentOrganisationTouchpoints.value
  }

  for (const touchpoint of currentOrganisationTouchpoints.value) {
    if (!dashboardObjectDeepClone.value.config.touchpoints) {
    // if there are no modifications to touchpoints in the dashboard config, show all touchpoints
      touchpoints.push(touchpoint)
      continue
    }

    const touchpointOverride = dashboardObjectDeepClone.value.config.touchpoints.find(t => t.id === touchpoint.id)

    if (!touchpointOverride) {
      touchpoints.push(touchpoint)
      continue
    }

    const customisedTouchpoint: HubTouchpointConfig = {
      ...touchpoint,
      ...touchpointOverride
    }

    touchpoints.push(customisedTouchpoint)
  }

  return touchpoints
}

function updateSection<HubSectionConfigKey extends keyof HubSectionConfig>(
  sectionId: string,
  key: HubSectionConfigKey,
  value: HubSectionConfig[HubSectionConfigKey]
) {
  if (!dashboardObjectDeepClone.value.config.sections) {
    dashboardObjectDeepClone.value.config.sections = []
  }

  const originalSection = allSections.value.find(s => s.id === sectionId)
  const sectionIndex: number = dashboardObjectDeepClone.value.config.sections.findIndex(s => s.id === sectionId)

  if (!originalSection) {
    return undefined
  }

  let newSectionConfig = {
    id: sectionId,
    [key]: value
  }

  if (key === 'items' && value && (value as Array<HubSectionItemConfig>).length) {
    const newSectionItems = [] as Array<PartialExceptTheseRequired<HubSectionItemConfig, 'id'>>

    for (const item of originalSection.items) {
      const relevantItem = (value as Array<HubSectionItemConfig>).find(i => i.id === item.id)
      if (!relevantItem) {
        continue
      }
      const sectionItemOverride = getDifference(item, relevantItem)

      if (objectIsEmpty(sectionItemOverride)) {
        continue
      }

      newSectionItems.push({ ...sectionItemOverride, id: item.id })
    }

    // @ts-expect-error - TS doesn't like this, but it's fine
    newSectionConfig = {
      id: sectionId,
      [key]: newSectionItems
    }
  }

  if (sectionIndex < 0) {
    dashboardObjectDeepClone.value.config.sections.push(newSectionConfig)
  } else {
    dashboardObjectDeepClone.value.config.sections[sectionIndex] = {
      ...dashboardObjectDeepClone.value.config.sections[sectionIndex],
      ...(newSectionConfig as Partial<HubSectionConfig>)
    }
  }

  updateDashboard()
}

function updateTouchpoint<HubTouchpointConfigKey extends keyof HubTouchpointConfig>(
  touchpointId: string,
  key: HubTouchpointConfigKey,
  value: HubTouchpointConfig[HubTouchpointConfigKey]
) {
  if (!dashboardObjectDeepClone.value.config.touchpoints) {
    dashboardObjectDeepClone.value.config.touchpoints = []
  }

  const touchpointIndex: number = dashboardObjectDeepClone.value.config.touchpoints.findIndex(
    t => t.id === touchpointId
  )

  if (touchpointIndex < 0) {
    dashboardObjectDeepClone.value.config.touchpoints.push({
      id: touchpointId,
      [key]: value
    })
  } else {
    dashboardObjectDeepClone.value.config.touchpoints[touchpointIndex] = {
      ...props.dashboardObject.config.touchpoints[touchpointIndex],
      // Required, or props.dashboardObject.config.touchpoints increased in length forever
      id: touchpointId,
      [key]: value
    }
  }

  updateDashboard()
}

async function updateDashboard(newConfig?: HubDashboardConfig, saveInDatabase: boolean = false) {
  if (newConfig) {
    dashboardObjectDeepClone.value.config = newConfig
  }

  if (!saveInDatabase) {
    dashboardStore.updateDashboard(
      route.params.id as string,
      props.dashboardObject.rootOrganisationNodeId,
      dashboardObjectDeepClone.value
    )
    return
  }

  if (saveInDatabase && (!canEditDashboard.value || !props.dashboardObject.isOwner)) {
    // TODO: Add option to create a new dashboard with current config if the user
    // tries to save without the required permissions

    // If the user doesn't have the permissions to save the dashboard, update the store rather than the API
    // and warn them that the changes won't be saved
    useHubToast(
      {
        title: t('dashboards.notifications.updateFailed'),
        description: t('dashboards.notifications.permissionDenied')
      },
      'warning'
    )
    return
  }

  try {
    const { data, error } = await useHubFetch<HubDashboard>(`api/v4/dashboards/${route.params.id}`, {
      method: 'PUT',
      body: {
        ordinal: props.dashboardObject.ordinal,
        name: props.dashboardObject.name,
        config: dashboardObjectDeepClone.value.config,
        rootOrganisationNodeId: props.dashboardObject.rootOrganisationNodeId
      }
    })

    if (!data.value) {
      throw new Error(error.value?.message)
    }

    useHubToast(t('dashboards.notifications.updated'), 'success')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    let description: string = ''

    if (error.message.includes('403')) {
      description = t('dashboards.notifications.permissionDenied')
    }

    useHubToast(
      {
        title: t('dashboards.notifications.updateFailed'),
        description
      },
      'danger'
    )
  }
}
</script>
