<template>
  <div
    :id="'component-base-wrapper-'+id"
    :class="'relative pb-4 print:min-w-full ' + customClasses"
  >
    <div v-if="showComponent && componentType">
      <component
        :is="componentType"
        :id="'component-base-actual-'+id"
        :key="'component-base-actual-'+id"
        ref="child"
        :header="relevantReportDefinition"
        v-bind="boundProperties"
        :settings="parsedReportData?.data?.settings ?? parsedReportData?.settings"
        :query-params="allQueryParams"
        :route-params="params"
        :dashboard-id="dashboardId"
        :touchpoint-id="touchpointId"
        :loading="componentDataLoading"
        @before-zoom="$emit('before-zoom', { ...$event })"
        @reset-zoom="$emit('reset-zoom')"
      />
    </div>
    <div v-else>
      <component
        :is="skeletonComponentMapping.get(relevantDisplayType) || skeletonComponentMapping.get('list')"
        ref="skeleton"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { truMetricsArray, type TruMetric, type HubFilter } from '~/types'
import type { HubDashboardComponentType, ServerDataResponse, TruHubDashboardComponent } from '~/types/component'
import type { FilterBarItem, HubComponentConfig, HubComponentSpecificReport } from '~/types/configuration'
import { formatQueryParamsForAPI } from '~/utils/api-helpers'

interface ComponentProps extends HubComponentConfig {
  id: string
  theme: 'mono' | 'colour'
  dashboardId: string
  touchpointId: string
  sectionFilters?: { [name: string]: string | number | undefined }
  sectionFilterBarConfig?: Array<FilterBarItem>
}

const props = defineProps<ComponentProps>()
const emit = defineEmits<{
  (e: 'error', arg1: unknown): void
  (e: 'before-zoom', { minX, maxX }: { minX: EpochTimeStamp, maxX: EpochTimeStamp }): void
  (e: 'reset-zoom'): void
}>()

const componentMapping = new Map<HubDashboardComponentType, {
  component: TruHubDashboardComponent
  hasLoadingState: boolean
}>([
  ['link', {
    component: defineAsyncComponent(() => import('@/components/hub/HubLink.vue')),
    hasLoadingState: false
  }],
  ['promoter', {
    component: defineAsyncComponent(() => import('@/components/hub/HubPromoter.vue')),
    hasLoadingState: false
  }],
  ['leaderboard', {
    component: defineAsyncComponent(() => import('@/components/hub/leaderboard/HubLeaderboard.vue')),
    hasLoadingState: false
  }],
  ['keyDayParts', {
    component: defineAsyncComponent(() => import('@/components/hub/HubKeyDayParts.vue')),
    hasLoadingState: false
  }],
  ['statistic', {
    component: defineAsyncComponent(() => import('@/components/hub/statistic/HubStatistic.vue')),
    hasLoadingState: false
  }],
  ['list', {
    component: defineAsyncComponent(() => import('@/components/hub/statistic/HubStatisticList.vue')),
    hasLoadingState: false
  }],
  ['table', {
    component: defineAsyncComponent(() => import('@/components/hub/table/HubTable.vue')),
    hasLoadingState: true
  }],
  ['tableList', {
    component: defineAsyncComponent(() => import('@/components/hub/table/HubTableList.vue')),
    hasLoadingState: true
  }],
  ['lineChart', {
    component: defineAsyncComponent(() => import('@/components/hub/chart/HubChartLine.vue')),
    hasLoadingState: false
  }],
  ['barChart', {
    component: defineAsyncComponent(() => import('@/components/hub/chart/HubChartBar.vue')),
    hasLoadingState: false
  }],
  ['timeBarChart', {
    component: defineAsyncComponent(() => import('@/components/hub/chart/HubChartTimeBar.vue')),
    hasLoadingState: false
  }],
  ['heatmapChart', {
    component: defineAsyncComponent(() => import('@/components/hub/chart/HubChartHeatMap.vue')),
    hasLoadingState: false
  }],
  ['pieChart', {
    component: defineAsyncComponent(() => import('@/components/hub/chart/HubChartPie.vue')),
    hasLoadingState: false
  }],
  ['commentSentiment', {
    component: defineAsyncComponent(() => import('@/components/comment/CommentSentiments.vue')),
    hasLoadingState: false
  }],
  ['commentMention', {
    component: defineAsyncComponent(() => import('@/components/comment/CommentMentions.vue')),
    hasLoadingState: false
  }]
])
const skeletonComponentMapping = new Map<HubDashboardComponentType, TruHubDashboardComponent>([
  ['list', defineAsyncComponent(() => import('@/components/skeleton/SkeletonList.vue'))],
  ['leaderboard', defineAsyncComponent(() => import('@/components/skeleton/SkeletonLeaderboard.vue'))],
  ['table', defineAsyncComponent(() => import('@/components/skeleton/SkeletonTable.vue'))],
  ['tableList', defineAsyncComponent(() => import('@/components/skeleton/SkeletonTableList.vue'))],
  ['keyDayParts', defineAsyncComponent(() => import('@/components/skeleton/SkeletonKeyDayParts.vue'))],
  ['heatmapChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartHeatmap.vue'))],
  ['lineChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartLine.vue'))],
  ['pieChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartPie.vue'))],
  ['commentSentiment', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartPie.vue'))],
  ['barChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartBar.vue'))],
  ['timeBarChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartBar.vue'))]
])

const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)
const { locale, t } = useI18n()

// local refs
const detailsEnabled: Ref<boolean> = ref(false)
const componentDataLoading: Ref<boolean> = ref(true)
const parsedReportData = ref()
const dataDisplayType: Ref<HubDashboardComponentType | undefined> = ref()

// computed refs
// -- component settings
const componentType = computed(() => componentMapping.get(relevantDisplayType.value)?.component)
const boundProperties = computed(() => {
  let boundProps = {}

  if (props.additionalOptions && !shouldUseFallbackReport.value && !shouldUseSpecificReport.value) {
    boundProps = {
      ...boundProps,
      ...props.additionalOptions
    }
  }

  if (props.fallbackAdditionalOptions && shouldUseFallbackReport.value) {
    boundProps = {
      ...boundProps,
      ...props.fallbackAdditionalOptions
    }
  }

  if (shouldUseSpecificReport.value && shouldUseSpecificReport.value.additionalOptions) {
    boundProps = {
      ...boundProps,
      ...shouldUseSpecificReport.value.additionalOptions
    }
  }

  boundProps = {
    ...boundProps,
    ...parsedReportData.value
  }

  const originalTheme = boundProps?.theme || 'colour'
  const theme = props.theme === 'mono' ? 'mono' : originalTheme

  return { ...boundProps, theme }
})
const showComponent: ComputedRef<boolean> = computed(() => {
  return relevantDisplayType.value === 'link'
    || (((parsedReportData.value && dataDisplayType.value === relevantDisplayType.value && !componentDataLoading.value)
      || (parsedReportData.value
        && componentMapping.get(relevantDisplayType.value)?.hasLoadingState
        && componentDataLoading.value)))
})

// -- query param handling (computed refs)
const standardQueryParams: ComputedRef<{ [name: string]: string | number | undefined }> = computed(() => {
  let formattedQueryParams = formatQueryParamsForAPI(params.value as HubFilter)
  const formattedSectionFilters = props.sectionFilters ? formatQueryParamsForAPI(props.sectionFilters) : {}

  if (formattedSectionFilters) {
    formattedQueryParams = {
      ...formattedQueryParams,
      ...formattedSectionFilters
    }
  }
  return {
    cultureInfo: locale.value ? locale.value.split('-')[0] : undefined,
    ...formattedQueryParams
  }
})
const reportSpecificQueryParams: ComputedRef<{ [name: string]: string | number | undefined }> = computed(() => {
  if (!props.queryParams && !props.fallbackReportQueryParams) {
    return {}
  }

  if (shouldUseSpecificReport.value) {
    return shouldUseSpecificReport.value.queryParams || {}
  }

  if (shouldUseFallbackReport.value && props.fallbackReportQueryParams) {
    return props.fallbackReportQueryParams
  }

  return props.queryParams || {}
})
const allQueryParams: ComputedRef<{
  [name: string]: string | number | Array<string | number> | undefined
}> = computed(() => ({
  ...standardQueryParams.value,
  ...reportSpecificQueryParams.value
}))

// -- component configuration usage, which report and display type etc
const requiredFiltersAreSelected = computed(() => {
  if (!props.requiredFilters || shouldUseSpecificReport.value) {
    return true // no filters are required or a specific report is being used
  }

  if (props.allFiltersRequired) {
    return props.requiredFilters.every(filter => standardQueryParams.value[filter])
  }

  return props.requiredFilters.some(filter => standardQueryParams.value[filter])
})
const shouldUseFallbackReport: ComputedRef<boolean> = computed(() => {
  if (!props.fallbackReportDefinition || !props.requiredFilters || shouldUseSpecificReport.value) {
    return false
  }

  if (!standardQueryParams.value || objectIsEmpty(standardQueryParams.value)) {
    return true
  }

  // if required filters are not selected, then should use fallback report
  return !requiredFiltersAreSelected.value
})
const relevantReportDefinition: ComputedRef<string> = computed(() => {
  // It doesn't realise that shouldUseFallbackReport checks for the fallbackReportDefinition
  if (shouldUseFallbackReport.value && props.fallbackReportDefinition) {
    return props.fallbackReportDefinition
  }

  if (shouldUseSpecificReport.value) {
    return shouldUseSpecificReport.value.reportDefinition
  }

  if (props.detailedReportDefinition && detailsEnabled.value) {
    return props.detailedReportDefinition
  }

  return props.reportDefinition
})
const isFakeReportDefinition: ComputedRef<boolean> = computed(() => {
  return relevantReportDefinition.value.includes('Fake')
})
const relevantDisplayType: ComputedRef<HubDashboardComponentType> = computed(() => {
  if (isFakeReportDefinition.value && props.fallbackReportDisplayType) {
    return props.fallbackReportDisplayType
  }

  if (shouldUseSpecificReport.value) {
    return shouldUseSpecificReport.value.displayType
  }

  return props.displayType
})

// -- metric filtering helpers
const isInStore: ComputedRef<boolean> = computed(() => {
  return !!(allQueryParams.value.touchpoint
    && (
      (Array.isArray(allQueryParams.value.touchpoint) && allQueryParams.value.touchpoint.includes('instore'))
      || allQueryParams.value.touchpoint === 'instore')
  )
})
const relevantTruMetrics: ComputedRef<Array<TruMetric>> = computed(() => {
  return truMetricsArray.filter(
    truMetric =>
      truMetric.name !== 'trurating'
      && (isInStore.value ? truMetric.name !== 'ease-of-use' : truMetric.name !== 'service')
  )
})

const shouldUseSpecificReport: ComputedRef<undefined | HubComponentSpecificReport> = computed(() => {
  if (!props.specificFilterReports || !props.specificFilterReports.length) {
    return undefined
  }

  for (const specificFilterReport of props.specificFilterReports) {
    // check for filter match one at a time

    const doesFilterMatch = (
      filterName: string,
      operator: '=' | '!=' | '>' | '<' | '>=' | '<=',
      expectedValue: string | number | boolean) => {
      if (!Object.prototype.hasOwnProperty.call(standardQueryParams.value, filterName)) {
        return false
      }

      // check that the filter matches the expected value (for these it could be null / undefined)
      switch (operator) {
        case '!=':
          return standardQueryParams.value[filterName] !== expectedValue
        case '=':
          return standardQueryParams.value[filterName] === expectedValue
      }

      // if no value then these operators are not valid
      if (!standardQueryParams.value[filterName]) {
        return false
      }

      switch (operator) {
        case '>':
          return standardQueryParams.value[filterName] > expectedValue
        case '<':
          return standardQueryParams.value[filterName] < expectedValue
        case '>=':
          return standardQueryParams.value[filterName] >= expectedValue
        case '<=':
          return standardQueryParams.value[filterName] <= expectedValue
        default:
          return false
      }
    }

    if (specificFilterReport.allFiltersRequired) {
      if (specificFilterReport.requiredFilters.every(x => doesFilterMatch(x.filterName, x.operator, x.value))) {
        return specificFilterReport
      }

      continue
    } else if (
      specificFilterReport.requiredFilters.some(x => doesFilterMatch(x.filterName, x.operator, x.value))) {
      return specificFilterReport
    }
  }

  return undefined
})

// watchers
watch(
  [
    () => allQueryParams,
    () => relevantReportDefinition,
    () => relevantDisplayType
  ],
  () => {
    getComponentData()
  },
  {
    immediate: true,
    deep: true
  }
)

// functions
async function fetchData(
  reportDefinition: string,
  customQueryParams?: { [name: string]: string | number | undefined }
): Promise<ServerDataResponse | undefined> {
  try {
    const response = await $hubFetch(`api/v4/dashboards/${props.dashboardId}/reports/${reportDefinition}`, {
      query: { ...allQueryParams.value, ...customQueryParams }
    })

    return response
  } catch (error) {
    emit('error', error)
  }

  return undefined
}
async function exportData(
  reportDefinition: string,
  customQueryParams?: { [name: string]: string | number | undefined },
  customFileName?: string
) {
  await $hubFetch(
    `api/v4/dashboards/${props.dashboardId}/reports/${reportDefinition}`,
    {
      query: { ...allQueryParams.value, ...customQueryParams },
      responseType: 'blob',
      onResponse: async ({ response }) => {
        const contentDisposition = response.headers.get('content-disposition')
        const defaultFileName = (customFileName || reportDefinition) + '-' + new Date().toJSON().slice(0, 10)
        let filename = defaultFileName

        if (contentDisposition) {
          const serverFilename = contentDisposition.split('filename=')[1]
          filename = serverFilename || defaultFileName

          if (serverFilename.includes('"=?utf-8?B?')) {
            const splitString = serverFilename.split('"=?utf-8?B?')[1]
            const encodedString = splitString.split('?="')[0]
            const decodedString = b64DecodeUnicode(encodedString)
            filename = decodedString || defaultFileName
          }
        }

        createExportFile(response._data, filename, 'xlsx', true)
      }
    },
    'xlsx'
  )
}
function exportComponentData(customFileNamePrefix?: string) {
  if (props.displayType === 'link') {
    // You cannot export a link
    return
  }

  if (
    isFakeReportDefinition.value
    && relevantReportDefinition.value.includes('FakeCoreQuestion')
    && relevantReportDefinition.value.includes('All')
  ) {
    for (const metric of relevantTruMetrics.value) {
      exportData(
        props.reportDefinition,
        {
          TruMetric: metric.name
        },
        customFileNamePrefix + '-' + props.reportDefinition + '-' + metric.name
      )
    }

    return
  }

  exportData(relevantReportDefinition.value, undefined, customFileNamePrefix + '-' + relevantReportDefinition.value)
}

async function handleFakeReport() {
  let fakeServerResponse

  if (relevantReportDefinition.value.includes('FakeCoreQuestion') && relevantReportDefinition.value.includes('All')) {
    const collectionOfAPICalls = []

    for (const metric of relevantTruMetrics.value) {
      const fetchMethod = fetchData(props.reportDefinition, {
        TruMetric: metric.name
      }) as Promise<ServerDataResponse>
      collectionOfAPICalls.push(fetchMethod)
    }

    const collectionOfResponses = await Promise.all(collectionOfAPICalls)

    fakeServerResponse = {
      rawApiParameters: collectionOfResponses[0].rawApiParameters,
      results: {
        columnHeadings: collectionOfResponses[0].results.columnHeadings,
        rows: collectionOfResponses.map(response => {
          const relevantMetric = relevantTruMetrics.value.find(
            truMetric => truMetric.name === response.rawApiParameters.truMetric
          )

          return {
            metric: {
              ...relevantMetric,
              displayName: t(`filters.truMetrics.${relevantMetric?.name}`)
            },
            rows: response.results.rows
          }
        })
      },
      _metaData: collectionOfResponses[0]._metaData
    }

    if (
      fakeServerResponse.results.rows.length === 0
      || fakeServerResponse.results.rows.every(row => row.rows.length === 0)
    ) {
      emit('error', {
        title: 'reports.errors.generic.header.lowOrNoData',
        statusMessage: 'reports.errors.generic.message.selectDifferentFilters'
      })
      return
    }
  }

  if (!fakeServerResponse) {
    return
  }

  // @ts-expect-error let it happen
  parsedReportData.value = parseReportData(fakeServerResponse, relevantDisplayType.value, true)
}

async function getComponentData() {
  componentDataLoading.value = true

  if ((!requiredFiltersAreSelected.value && !props.fallbackReportDefinition) || relevantDisplayType.value === 'link') {
    // if the required filters aren't selected and there's no fallback report definition
    // don't fetch data & make sure the component is empty
    parsedReportData.value = undefined
    componentDataLoading.value = false
  }

  if (isFakeReportDefinition.value) {
    // If it is not a real report definition, handle it differently
    await handleFakeReport()
    dataDisplayType.value = relevantDisplayType.value
    componentDataLoading.value = false
    return
  }

  try {
    let parsedData
    const data = await fetchData(relevantReportDefinition.value) as ServerDataResponse

    if (data.results.rows.length === 0) {
      parsedReportData.value = undefined
      emit('error', {
        title: 'reports.errors.generic.header.lowOrNoData',
        statusMessage: 'reports.errors.generic.message.selectDifferentFilters'
      })
      componentDataLoading.value = false
      return
    }

    if (relevantDisplayType.value.includes('comment')) {
      parsedData = parseCommentData(
        data as ServerDataResponse,
        relevantDisplayType.value.toLowerCase().split('comment')[1] as 'list' | 'mention' | 'sentiment'
      )

      // Manually add additional options for the comment components
      parsedData = {
        ...parsedData,
        alwaysShowHeading: true
      }
    } else {
      parsedData = parseReportData(data as ServerDataResponse, relevantDisplayType.value)
    }

    parsedReportData.value = parsedData
    dataDisplayType.value = relevantDisplayType.value
  } catch (error) {
    emit('error', error)
  }

  componentDataLoading.value = false
}

// expose function to parent
defineExpose({
  exportComponentData
})
</script>
