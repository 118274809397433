<template>
  <div
    :id="touchpoint.id"
    :class="touchpoint.ordinal > 0 ? 'print:break-inside-avoid-page' : ''"
  >
    <DashboardTouchpointBanner
      v-bind="touchpoint"
      v-model:visibility-toggle="visibilityToggle"
      v-model:show-settings-dialog="showSettingsDialog"
      :dashboard-id="dashboard.id"
      @update="
        (arg1: HubTouchpointConfigKey, arg2: HubTouchpointConfig[HubTouchpointConfigKey]) =>
          $emit('update-touchpoint', touchpoint.id, arg1, arg2)
      "
    />
    <div
      v-if="touchpoint.isVisible || dashboard.config.touchpointDisplayType == 'tabs'"
      :id="touchpoint.id + '-sections'"
      class="my-4"
    >
      <HubTabs
        v-if="dashboard.config.sectionDisplayType == 'tabs'"
        v-model:active-index="activeSectionIndex"
        :items="enabledSections"
        class="mb-4"
        header-key="label"
        theme="blue"
        lazy
        @update:active-index="(index: number) => selectSection(enabledSections[index].id)"
      >
        <template #header="{ header, item }">
          {{ item.translateLabel ? $t('dashboards.section.name.' + header) : header }}
        </template>
        <template #item="{ item }">
          <DashboardSection
            v-bind="(item as HubSectionConfig)"
            :dashboard-id="dashboard.id"
            :touchpoint-id="touchpoint.id"
            :touchpoint-filters="touchpoint.queryParams"
            force-visible
            @update="(arg1: HubSectionConfigKey, arg2: HubSectionConfig[HubSectionConfigKey]) =>
              $emit('update-section', item.id, arg1, arg2)"
          />
        </template>
      </HubTabs>

      <DashboardSection
        v-for="section in enabledSections"
        v-else
        :key="section.id"
        class="mx-4"
        v-bind="section"
        :dashboard-id="dashboard.id"
        :touchpoint-id="touchpoint.id"
        :touchpoint-filters="touchpoint.queryParams"
        show-header
        @update="
          (arg1: HubSectionConfigKey, arg2: HubSectionConfig[HubSectionConfigKey]) =>
            $emit('update-section', section.id, arg1, arg2)
        "
      />
    </div>

    <LazyDashboardTouchpointSettings
      v-model="showSettingsDialog"
      v-bind="(props.touchpoint as HubTouchpointConfig)"
      :sections="sections"
      :section-display-type="dashboard.config.sectionDisplayType"
      @update="(arg1: HubTouchpointConfigKey, arg2: HubTouchpointConfig[HubTouchpointConfigKey]) =>
        $emit('update-touchpoint', touchpoint.id, arg1, arg2)"
      @update:section-display-type="$emit('update-dashboard', { ...dashboard.config, sectionDisplayType: $event })"
    />
  </div>
</template>

<script setup lang="ts">
import type { PartialExceptTheseRequired } from '~/types'
import type {
  HubDashboard,
  HubSectionConfig,
  HubTouchpointConfigKey,
  HubTouchpointConfig,
  HubSectionConfigKey,
  PartialHubSectionConfig,
  HubSectionItemConfig,
  HubDashboardConfig
} from '~/types/configuration'

const props = defineProps<{
  touchpoint: HubTouchpointConfig
  dashboard: HubDashboard
}>()

defineEmits<{
  (e: 'update-section', arg1: string, arg2: HubSectionConfigKey, arg3: HubSectionConfig[HubSectionConfigKey]): void
  (
    e: 'update-touchpoint',
    arg1: string,
    arg2: HubTouchpointConfigKey,
    arg3: HubTouchpointConfig[HubTouchpointConfigKey]
  ): void
  (e: 'update-dashboard', arg1: HubDashboardConfig): void
}>()

// store refs
const sectionStore = useSectionStore()
const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)
const { allSections } = storeToRefs(sectionStore)

const visibilityToggle = computed(() => {
  const selectedTouchpointIds = params.value.touchpointIds

  if ((selectedTouchpointIds && !Array.isArray(selectedTouchpointIds))
    || (Array.isArray(selectedTouchpointIds) && selectedTouchpointIds.length === 1)) {
    return false
  }

  return props.dashboard.config.touchpointDisplayType === 'list'
})

// internal refs
const showSettingsDialog = ref(false)
const activeSectionIndex = ref()

// computed refs
const sections: ComputedRef<Array<HubSectionConfig>> = computed(() => {
  const sections: Array<HubSectionConfig> = []

  for (const section of allSections.value) {
    if (!props.dashboard.config.sections) {
      // if there are no sections defined in the dashboard config, use the default sections
      sections.push(section)
      continue
    }

    const sectionOverride: PartialHubSectionConfig | undefined = getSectionOverride(section.id)

    if (!sectionOverride || objectIsEmpty(sectionOverride)) {
      sections.push(section)
      continue
    }

    const customisedSection: HubSectionConfig = {
      ...section,
      ...sectionOverride,
      items: section.items
    }

    if (sectionOverride.items && sectionOverride.items.length) {
      customisedSection.items = []
      for (const item of section.items) {
        const sectionItemOverride = sectionOverride.items.find(i => i.id === item.id)

        if (!sectionItemOverride) {
          customisedSection.items.push(item)
          continue
        }

        customisedSection.items.push({ ...item, ...sectionItemOverride })
      }
    }

    sections.push(customisedSection)
  }

  return sections.sort((a, b) => a.ordinal - b.ordinal)
})
const enabledSections: ComputedRef<Array<HubSectionConfig>> = computed(() => sections.value.filter(s => s.isEnabled))

// functions
function getSectionOverride(sectionId: string): PartialHubSectionConfig | undefined {
  const dashboardSectionOverride = props.dashboard.config.sections.find(s => s.id === sectionId)
  const touchpointSectionOverride = props.touchpoint.sections.find(s => s.id === sectionId)

  if (!dashboardSectionOverride && !touchpointSectionOverride) {
    return undefined
  }

  if (dashboardSectionOverride && touchpointSectionOverride) {
    const sectionOverride = {
      ...touchpointSectionOverride,
      ...dashboardSectionOverride
    }

    const sectionOverrideItems: Array<PartialExceptTheseRequired<HubSectionItemConfig, 'id'>> = []

    if (touchpointSectionOverride.items && touchpointSectionOverride.items.length) {
      sectionOverrideItems.push(...touchpointSectionOverride.items)
    }

    if (dashboardSectionOverride.items && dashboardSectionOverride.items.length) {
      if (sectionOverrideItems.some(i => dashboardSectionOverride.items?.some(di => i.id === di.id))) {
        for (const item of sectionOverrideItems) {
          const itemOverride = dashboardSectionOverride.items.find(i => i.id === item.id)
          if (!itemOverride) {
            continue
          }

          // do magic to work out if we care
        }
      } else {
        sectionOverrideItems.push(...dashboardSectionOverride.items)
      }
    }

    return {
      ...sectionOverride,
      items: sectionOverrideItems
    }
  }

  if (dashboardSectionOverride) {
    return dashboardSectionOverride
  }

  return touchpointSectionOverride
}
function selectSection(sectionId: string) {
  params.value.hash = props.touchpoint.id + ':' + sectionId
}

// watchers
watch(
  [() => params.value.hash, () => sections.value],
  () => {
    if (!params.value.hash) {
      return
    }

    const [touchpointId, sectionId] = params.value.hash.split(':')
    // Only update if the touchpoint is in the anchor
    if (touchpointId === props.touchpoint.id) {
      const sectionIndex = sections.value.findIndex(s => s.id === sectionId)
      if (sectionIndex === -1) {
        return
      }
      activeSectionIndex.value = sectionIndex
    }
  },
  {
    immediate: true
  }
)
</script>
