<template>
  <div>
    <div class="mt-2 flex items-baseline justify-between gap-4 text-grey-blue md:mt-0 md:justify-end">
      <template v-if="dashboard.metadata?.lastRefreshDateTimeUtc ">
        <UTooltip
          :popper="{ arrow: true, resize: true, scroll: false }"
          :ui="{
            base: 'h-auto text-xs text-clip text-wrap py-2 px-3 [@media(pointer:coarse)]:!block',
          }"
        >
          <div
            id="last-refreshed-time"
            class="flex items-center justify-start gap-1 text-2xs font-medium"
          >
            <UIcon
              name="i-mdi-refresh"
              class="!size-5"
            />
            {{ dayjs(dashboard.metadata?.lastRefreshDateTimeUtc).format('HH:mm') }}
          </div>
          <template #text>
            {{
              $t('dashboards.lastRefreshDate', {
                date: dayjs(dashboard.metadata?.lastRefreshDateTimeUtc).format('DD MMM YYYY HH:mm'),
              })
            }}
          </template>
        </UTooltip>

        <UDivider
          class="hidden h-5 md:block"
          :ui="{ border: { base: 'flex border-dark-grey' } }"
          orientation="vertical"
        />
      </template>
      <div
        id="dashboard-actions"
        class="flex items-center justify-end gap-3"
      >
        <UIcon
          id="dashboard-favourite-icon"
          :name="dashboard.isStarred ? 'i-mdi-star': 'i-mdi-star-outline'"
          class="!size-5 cursor-pointer"
          @click="setDashboardState(!dashboard.isStarred, !!dashboard.isDefault)"
        />
        <UIcon
          id="dashboard-shared-icon"
          :name="(dashboard.groups.length || !dashboard.isOwner)
            ? 'i-mdi-users' : 'i-mdi-lock'"
          class="!size-5 cursor-pointer"
        />
        <UIcon
          id="dashboard-print-icon"
          name="i-mdi-printer"
          class="!size-5 cursor-pointer "
          @click="printPage"
        />
        <UIcon
          v-if="canEditDashboard && dashboard.isOwner"
          id="dashboard-save-icon"
          name="i-mdi-content-save"
          class="!size-5 cursor-pointer"
          @click="emit('save')"
        />
        <HubDropdown
          id="dashboard-actions-dropdown"
          :items="dashboardActions"
        >
          <UIcon
            name="i-mdi-dots-horizontal"
            class="!size-6"
          />
        </HubDropdown>
      </div>
    </div>

    <LazyDashboardUpdateNameModal
      v-if="canEditDashboard || canCreateDashboard"
      v-model="showUpdateNameModal"
      :dashboard-id="dashboard.id"
      :dashboard-object="dashboard"
      :name="dashboard.name"
      :save-as-new="saveAsNewDashboard"
      :is-dashboard-shared="dashboard.groups.length > 0"
      :is-dashboard-name-translated="dashboard.translateName"
    />
    <LazyDashboardUpdateOwnerModal
      v-if="canEditDashboard && dashboard.ownerUserId"
      v-model="showUpdateOwnerModal"
      :dashboard-id="dashboard.id"
      :owner-id="dashboard.ownerUserId"
      :owner-name="(dashboard.ownerUserEmail as string)"
      :root-org-id="dashboard.rootOrganisationNodeId"
      :is-dashboard-shared="dashboard.groups.length > 0"
    />
    <LazyDashboardSettings
      v-model="showSettingsDialog"
      v-bind="(dashboard.config as HubDashboardConfig)"
      :touchpoints="touchpoints"
      @save="emit('update:dashboard', { ...dashboard, config: $event })"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import type { HubDashboard, HubDashboardConfig, HubTouchpointConfig } from '~/types/configuration'
import type { HubDropdownItem } from '~/types/component'
import HubConfirmationModal from '~/components/hub/modal/HubConfirmationModal.vue'

const { t } = useI18n()

const props = defineProps<{
  dashboard: HubDashboard
  touchpoints: Array<HubTouchpointConfig>
}>()

const emit = defineEmits<{
  (e: 'update:dashboard', arg1: HubDashboard): void
  (e: 'save'): void
}>()

// stores
const dashboardStore = useDashboardStore()
const userStore = useUserStore()
const { userHomePage } = storeToRefs(userStore)

// internal refs
const showSettingsDialog = ref(false)
const showUpdateNameModal = ref(false)
const showUpdateOwnerModal = ref(false)
const saveAsNewDashboard = ref(false)

// computed permissions
const canEditDashboard: ComputedRef<boolean> = computed(() => {
  // When we add organisation updates, this will be the correct permissions, but right now only owner can update
  // return checkPermissions({
  //   permissions: props.dashboard.isOwner
  //     ? 'Dashboards.Default.Self.Update'
  //     : 'Dashboards.Default.Organisation.Update'
  // })
  return props.dashboard.isOwner && checkPermissions({
    permissions: 'Dashboards.Default.Self.Update'
  })
})
const canDeleteDashboard: ComputedRef<boolean> = computed(() => {
  return checkPermissions({
    permissions: props.dashboard.isOwner
      ? 'Dashboards.Default.Self.Delete'
      : 'Dashboards.Default.Organisation.Delete'
  })
})
const canCreateDashboard: ComputedRef<boolean> = computed(() => {
  return checkPermissions({
    permissions: 'Dashboards.Default.Self.Create'
  })
})

// computed dashboard actions
const dashboardActions: ComputedRef<Array<Array<HubDropdownItem>>> = computed(() => ([
  [
    {
      label: t('setAsMyDefault'),
      hidden: !!props.dashboard.isDefault,
      click: () => setDashboardState(!!props.dashboard.isStarred, true)
    },
    {
      label: t('dashboards.modal.rename.title'),
      hidden: !canEditDashboard.value,
      click: () => {
        showUpdateNameModal.value = true
      }
    },
    {
      label: t('configureLayout'),
      hidden: !canEditDashboard.value,
      click: () => {
        showSettingsDialog.value = true
      }
    }
  ],
  [
    {
      label: t('dashboards.modal.saveAsNew.title'),
      hidden: !canCreateDashboard.value,
      click: () => {
        saveAsNewDashboard.value = true
        showUpdateNameModal.value = true
      }
    },
    {
      label: t('copyLink'),
      click: () => {
        navigator.clipboard.writeText(window.location.href)
        useHubToast(t('linkCopied'), 'success')
      }
    },
    {
      label: t('share'),
      hidden: !canEditDashboard.value
    },
    {
      label: t('dashboards.modal.transferOwnership.title'),
      hidden: !canEditDashboard.value,
      click: () => {
        showUpdateOwnerModal.value = true
      }
    }
  ],
  [
    {
      label: t('delete'),
      iconColor: 'red-500',
      icon: 'i-mdi-trash-can',
      hidden: !canDeleteDashboard.value,
      click: () => deleteConfirmation()
    }
  ]
]))

// functions
function printPage() {
  if (window) {
    window.print()
  }
}

function deleteConfirmation() {
  const modal = useModal()

  modal.open(HubConfirmationModal, {
    title: 'dashboards.delete',
    warning: 'dashboards.modal.generic.deleteWarning',
    onConfirm: deleteDashboard
  })
}

async function deleteDashboard() {
  try {
    await $hubFetch(`api/v4/dashboards/${props.dashboard.id}`, {
      method: 'delete'
    })
    useHubToast(t('dashboards.notifications.deleted'), 'success')
    navigateTo(userHomePage.value, { replace: true })
  } catch (error: unknown) {
    useHubToast({ title: t('dashboards.notifications.deleteFailed'), description: error }, 'danger')
  }

  dashboardStore.fetchDashboardsForOrganisation(props.dashboard.rootOrganisationNodeId, true)
}

async function setDashboardState(isStarred: boolean, isDefault: boolean) {
  await $hubFetch(`api/v4/dashboards/${props.dashboard.id}/state`, {
    method: 'put',
    body: {
      isStarred,
      isDefault
    }
  })

  emit('update:dashboard', { ...props.dashboard, isStarred, isDefault })
}
</script>
